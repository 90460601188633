<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      back
    />
    <b10-page-content>
      <v-sheet
        class="ma-2"
      >
        <v-calendar
          v-model="fecha"
          type="month"
          :events="formattedEventos"
          event-color="secondary"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          @change="changeCalendar"
          @click:event="clickEvento"
          @click:date="clickDia"
        />
      </v-sheet>
      <b10-fab-button
        left
        :icon="$vuetify.icons.values.prev"
        @click="clickSiguienteAnteriorMes(-1)"
      />
      <b10-fab-button
        :icon="$vuetify.icons.values.next"
        @click="clickSiguienteAnteriorMes(1)"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import { basePageMixin } from '@/mixins/basePageMixin'
import Data from './TiempoAgendaData'
import { addMonths, firstDayOfMonth, format as formatDate, currentDate, toDate, diff, msToHHmm } from '@/utils/date'
import { get, sync } from 'vuex-pathify'
import _ from '@/utils/lodash'

const pageStoreName = 'pagesTiempoAgenda'

export default {
  mixins: [basePageMixin],
  beforeRouteLeave (to, from, next) {
    if (!this.beforeRouteLeaveBase(to, from, next)) {
      if (!this.rememberState) {
        this.fecha = null
      }
      next()
    }
  },
  data () {
    return {
      pageStoreName,
    }
  },
  computed: {
    fecha: sync(`${pageStoreName}/fecha`),
    eventos: sync(`${pageStoreName}/eventos`),
    usuarioIdEmpleado: get('usuario/idempleado'),
    formattedEventos () {
      var eventosFormatted = [];
      for (var dia of this.eventos) {
        let evento = _.find(eventosFormatted, { start: toDate(dia.finicio) })
        if (evento) {
          evento.total = evento.total + diff(dia.ffin, dia.finicio)
        }
        else {
          evento = {
            start: toDate(dia.finicio),
            end: toDate(dia.finicio),
            total: diff(dia.ffin, dia.finicio),
          }
          eventosFormatted.push(evento)
        }
        evento.name = msToHHmm(evento.total)
      }
      return eventosFormatted
    },
  },
  watch: {
    fecha (newValue) {
      if (newValue) {
        this.title = `Tiempos ${formatDate(toDate(newValue), 'MMMM yyyy')}`
      } else {
        this.title = 'Tiempos'
      }
    }
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      if (!this.fecha) {
        this.fecha = firstDayOfMonth(currentDate())
      }
      const [dataset] = await Data.selectTiempoTrabajadoMes(
        this, this.usuarioIdEmpleado, toDate(this.fecha)
      )
      this.eventos = dataset
    },
    async changeCalendar () {
      await this.loadPage()
    },
    goToTiempoList(fecha) {
      this.rememberState = true
      this.rememberScroll = true
      this.$appRouter.push({
        name: 'tiempos__tiempo-list',
        params: {
          _filter: {
            fdesde: {
              value: fecha,
              options: { fixed: true },
            },
            fhasta: {
              value: fecha,
              options: { fixed: true },
            },
          },
        },
      })
    },
    clickEvento (evento) {
      this.goToTiempoList(toDate(evento.event.start))
    },
    clickDia (dia) {
      this.goToTiempoList(toDate(dia.date))
    },
    async clickSiguienteAnteriorMes (offset) {
      this.fecha = addMonths(toDate(this.fecha), offset)
    },
  }
}
</script>
