import { firstDayOfMonth, lastDayOfMonth } from '@/utils/date'
import { APIFilter } from '@/utils/api'

export default {
  async selectTiempoTrabajadoMes (Vue, idempleado, fecha) {
    const fdesde = firstDayOfMonth(fecha)
    const fhasta = lastDayOfMonth(fecha)
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idempleado', idempleado)
      .addGT('estado', 0)
      .addGTE('finicio', fdesde)
      .addLTE('finicio', fhasta)
    const resp = await Vue.$api.call('tiempoTrabajado.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  }
}